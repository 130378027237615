module.exports = {
  siteTitle: "Programming Blah", // <title>
  shortSiteTitle: "Programming Blah", // <title> ending for posts and pages
  siteDescription: "Programming Blah",
  siteUrl: "https:/programmingblah.com",
  pathPrefix: "",
  siteImage: "static/siteImage.png",
  siteLanguage: "en",

  /* author */
  authorName: "Anshul Srivastava",
  authorTwitterAccount: "",
  authorGithub: "https://github.com/anshul-srivastava",
  //authorStackoverflow: "https://stackexchange.com/users/5678032/atte-juvonen?tab=accounts",
  authorLinkedin: "https://www.linkedin.com/in/anshul-srivastava-8189a270/",
  //authorCodeforces: "https://codeforces.com/profile/baobab",
  authorYoutube: "",

  /* header text */
  headerTitle: "Programming Blah",
  headerSubTitle: "",

  /* manifest.json */
  manifestName: "Anshul Srivastava",
  manifestShortName: "anshul", // max 12 characters
  manifestStartUrl: "/index.html",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",

  /* Contact form email destination. */
  contactPostAddress: "",

  /* Email newsletter subscription link */
  emailSubLink: ""
};
